import React, { useState, useEffect, useRef, useCallback } from 'react';
import metadata from './metadata.json';
import './Slideshow.css';

function Slideshow() {
  const [nftData, setNftData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [speed, setSpeed] = useState(10000); // 10 seconds default (1x)
  const [isPlaying, setIsPlaying] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const preloadedImagesRef = useRef({});
  const timerRef = useRef(null);
  const [listingDetails, setListingDetails] = useState(null);

  useEffect(() => {
    const shuffledData = shuffleArray([...metadata]);
    setNftData(shuffledData);
    const initialIndex = Math.floor(Math.random() * shuffledData.length);
    setCurrentIndex(initialIndex);

    // Fetch listing details for the initial NFT
    fetchListingDetails(shuffledData[initialIndex]);
  }, []);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const preloadImage = useCallback((index) => {
    if (nftData.length === 0 || preloadedImagesRef.current[index]) return;

    const img = new Image();
    img.src = nftData[index].image;
    img.onload = () => {
      preloadedImagesRef.current[index] = true;
    };
  }, [nftData]);

  useEffect(() => {
    if (nftData.length > 0) {
      const nextIndex = (currentIndex + 1) % nftData.length;
      preloadImage(nextIndex);
    }
  }, [currentIndex, nftData, preloadImage]);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (isPlaying && nftData.length > 0) {
      timerRef.current = setInterval(nextSlide, speed);
    }
  }, [isPlaying, speed, nftData]);

  const nextSlide = useCallback(() => {
    if (nftData.length === 0 || transitioning) return;
    setTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % nftData.length);
      setTimeout(() => {
        setTransitioning(false);
      }, 500); // Match this with the CSS transition duration
    }, 500); // Match this with the CSS transition duration
    resetTimer();
  }, [nftData, resetTimer, transitioning]);

  const prevSlide = useCallback(() => {
    if (nftData.length === 0 || transitioning) return;
    setTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + nftData.length) % nftData.length);
      setTimeout(() => {
        setTransitioning(false);
      }, 500); // Match this with the CSS transition duration
    }, 500); // Match this with the CSS transition duration
    resetTimer();
  }, [nftData, resetTimer, transitioning]);

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [resetTimer, speed, isPlaying]);

  const togglePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const toggleSpeed = () => {
    setSpeed(prevSpeed => {
      if (prevSpeed === 10000) return 5000; // 1x to 2x
      if (prevSpeed === 5000) return 3500; // 2x to 3x
      return 10000; // 3x back to 1x
    });
  };

  const getSpeedLabel = () => {
    if (speed === 10000) return '1x';
    if (speed === 5000) return '2x';
    return '3x';
  };

  const fetchListingDetails = async (nft) => {
    if (!nft) return;

    const mintAddress = nft.mint_address;
    if (!mintAddress) {
      console.error('Mint address is undefined for the current NFT');
      setListingDetails(null);
      return;
    }

    try {
      const url = `/api/v2/tokens/${mintAddress}/listings`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      if (data && data.length > 0) {
        const listing = data[0];
        setListingDetails({
          listed: true,
          price: listing.price,
        });
      } else {
        setListingDetails({
          listed: false,
          price: null,
        });
      }
    } catch (error) {
      console.error('Error fetching listing details:', error);
      setListingDetails(null);
    }
  };

  useEffect(() => {
    if (nftData.length > 0 && currentIndex >= 0) {
      fetchListingDetails(nftData[currentIndex]);
    }
  }, [currentIndex, nftData]);

  if (nftData.length === 0) {
    return <div className="loading">Loading...</div>;
  }

  const currentNFT = nftData[currentIndex];

  if (!currentNFT) {
    return <div className="loading">Error loading NFT data</div>;
  }

  return (
    <div className="slideshow">
      <h1 className="title">Mimany Gallery</h1>
      <div className="content-container">
        <div className={`image-wrapper ${transitioning ? 'transitioning' : ''}`}>
          <img
            src={currentNFT.image}
            alt={`NFT ${currentIndex + 1}`}
            className="nft-image"
            onClick={() => handleImageClick(currentNFT.mint_address)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="right-container">
          <div className={`attributes-container ${transitioning ? 'transitioning' : ''}`}>
            <h2 className="nft-name">{currentNFT.name}</h2>
            <div className="attributes-wrapper">
              {currentNFT.attributes
                .filter(attr => attr.value !== null && attr.value !== '')
                .map((attr, index) => (
                  <p key={index} className="attribute">
                    <strong>{attr.trait_type}:</strong> {attr.value}
                  </p>
                ))}

              {listingDetails && (
                <div className="listing-info">
                  <p className="attribute">
                    <strong>Status:</strong> {listingDetails.listed ? 'For Sale' : 'Not Listed'}
                    {listingDetails.listed && listingDetails.price && (
                      <span className="price"> {listingDetails.price} SOL</span>
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="controls-container">
            <div className="controls">
              <button onClick={prevSlide} className="nav-button prev-button" aria-label="Previous">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                </svg>
              </button>
              <button onClick={togglePlayPause} className="nav-button play-pause-button" aria-label={isPlaying ? 'Pause' : 'Play'}>
                {isPlaying ? (
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                  </svg>
                ) : (
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5v14l11-7z" />
                  </svg>
                )}
              </button>
              <button onClick={nextSlide} className="nav-button next-button" aria-label="Next">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                </svg>
              </button>
              <button onClick={toggleSpeed} className="nav-button speed-button" aria-label="Toggle Speed">
                {getSpeedLabel()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const handleImageClick = (mint_address) => {
  const baseUrl = 'https://magiceden.us/item-details/';
  window.open(baseUrl + mint_address, '_blank');
};

export default Slideshow;